import { Alert, Form, Typography } from 'antd';
import React, {
  FC, useCallback, useEffect, useState,
} from 'react';
import ClientDetailsForm from './ClientDetailsForm';
import { ContainerWithForm } from '../../components/Container';
import { SPECIALITIES_ROUTE } from '../../constants/routes';
import FooterNavigator from '../../components/FooterNavigator';
import { useAppDispatch } from '../../hooks/redux-hooks';
import { addConsentsData, updateBookingClientData } from '../../redux/slices/booking';
import { CONSENTS_MAPPING } from '../../constants/consents';
import Consents from './Consents';
import { removeEmptyStrFields } from '../../utils/object-check';
import { DATE_WITH_DASH } from '../../constants/time-formats';
import analytics from '../../infra/analytics';
import { NEW_PATIENT_REGISTRATION, PAGE_VIEW_EVENT, SITE_NAME } from '../../constants/analytics';

const ClientDetails: FC = () => {
  const [isAgreedThePrivacyPolicy, setIsAgreedThePrivacyPolicy] = useState(false);
  const [consentsHasError, setConsentsHasError] = useState(false);
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();

  useEffect(() => {
    analytics.logPageViewEvent(PAGE_VIEW_EVENT, {
      site: SITE_NAME,
      page_name: NEW_PATIENT_REGISTRATION,
    });
  }, []);

  const submit = useCallback(async () => {
    if (!isAgreedThePrivacyPolicy) {
      setConsentsHasError(true);
      return false;
    }

    try {
      const values = await form.validateFields();
      const {
        nextOfKin, addressResidential, diffPostalAddress, ...otherValues
      } = values;
      const addressPostal = (diffPostalAddress) ? values.addressPostal : addressResidential;

      dispatch(updateBookingClientData({
        ...removeEmptyStrFields(otherValues),
        addressPostal: removeEmptyStrFields(addressPostal),
        addressResidential: removeEmptyStrFields(addressResidential),
        nextOfKin: removeEmptyStrFields(nextOfKin),
        dateOfBirth: values.dateOfBirth.format(DATE_WITH_DASH),
      }));

      dispatch(addConsentsData({
        isAgreedThePrivacyPolicy: { ...CONSENTS_MAPPING.isAgreedThePrivacyPolicy, agreed: true },
      }));

      return true;
    } catch (errorInfo) {
      return false;
    }
  }, [form, dispatch, isAgreedThePrivacyPolicy]);

  return (
    <ContainerWithForm>
      <Typography.Title level={2}>
        New Patient Registration
      </Typography.Title>

      <Typography.Paragraph strong style={{ marginBottom: '50px' }}>
        Thank you for choosing Telecare. To book your specialist or allied health appointment, please fill out the following form.
      </Typography.Paragraph>

      <Typography.Title level={5}>
        Your Contact Details
      </Typography.Title>
      <ClientDetailsForm form={form} />

      <Consents
        isAgreedThePrivacyPolicy={isAgreedThePrivacyPolicy}
        setIsAgreedThePrivacyPolicy={setIsAgreedThePrivacyPolicy}
      />

      {consentsHasError && (
      <Alert
        message="Please agree to the privacy policy before you continuing."
        type="error"
      />
      )}

      <FooterNavigator
        error=""
        submit={submit}
        next={SPECIALITIES_ROUTE}
        hasBack={false}
        containerStyle={{ marginTop: '40px' }}
      />
    </ContainerWithForm>
  );
};

export default ClientDetails;
