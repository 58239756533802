import React, { FC, useEffect } from 'react';
import { Spin } from 'antd';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import SpecialistList from '../../components/SpecialistList';
import { useGetSpecialistsQuery } from '../../services/specialists';
import { DEVICES } from '../../styles/breakpoint';
import { selectBookingData } from '../../redux/slices/booking';
import analytics from '../../infra/analytics';
import {
  PAGE_VIEW_EVENT, SELECT_SPECIALIST, SITE_NAME,
} from '../../constants/analytics';

const SpecialistsContainer = styled.div`
  text-align: center;
  margin: 20px auto;
  width: 98%;
  
  ${DEVICES.desktop} {
    width: 80%;
    margin: 50px auto 100px;
  }
`;

const Specialists: FC = () => {
  const { speciality } = useSelector(selectBookingData);
  const { data: specialists, isLoading } = useGetSpecialistsQuery(speciality?.id || '');

  useEffect(() => {
    analytics.logPageViewEvent(PAGE_VIEW_EVENT, {
      site: SITE_NAME,
      page_name: SELECT_SPECIALIST,
    });
  }, []);

  return (
    <SpecialistsContainer>
      <Spin tip="Please wait while we bring up each specialist's availabilities..." spinning={isLoading}>
        {specialists && <SpecialistList specialists={specialists} />}
      </Spin>
    </SpecialistsContainer>
  );
};

export default Specialists;
