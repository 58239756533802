import { Col, Row, Spin } from 'antd';
import React, {
  FC, useCallback, useContext, useMemo,
} from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { useAppDispatch } from '../../hooks/redux-hooks';
import { selectBookingAppointmentData, updateBookingAppointmentData } from '../../redux/slices/booking';
import { BookingModal, ColWithBar } from './style';
import TitleWithIcon from '../TitleWithIcon';
import SelectedTime from '../SelectedTimeDisplaySection';
import BookingCalendar from '../BookingCalendar';
import AvailableTimeslotList from '../AvailableTimeslotList';
import Specialist from '../../model/Specialist';
import { AppointmentData, AppointmentType } from '../../model';
import { DOCUMENTS_UPLOADING } from '../../constants/routes';
import { useGetAvailabilitiesBySpecialistQuery } from '../../services/availabilities';
import AvailableTime from '../../model/AvailableTime';
import { BookingTimeContext } from '../SpecialistCard';

// eslint-disable-next-line no-unused-vars
const SelectBookingTimeModal: FC<{ specialist: Specialist, isBookingTimeModalOpen: boolean, setIsBookingTimeModalOpen: (isOpen: boolean) => void }> = ({ specialist, isBookingTimeModalOpen, setIsBookingTimeModalOpen }) => {
  const { type } = useSelector(selectBookingAppointmentData) as AppointmentData;
  const { bookingTime, updateBookingTime } = useContext(BookingTimeContext);
  const isMobile = useMediaQuery({
    query: '(max-width: 1280px)',
  });
  const { appointmentLength, id: specialistId } = specialist;
  const appointmentType = useMemo(() => type ?? AppointmentType.NEW, [type]);
  const navigate = useNavigate();

  const aptLength = useMemo(() => appointmentLength?.split('-')[0], [appointmentLength]) as string;

  const dispatch = useAppDispatch();
  const handleOK = useCallback(() => {
    const { month, date, startTime } = bookingTime;
    setIsBookingTimeModalOpen(false);
    if (month && date && startTime) {
      dispatch(updateBookingAppointmentData({
        date, startTime, aptLength, specialistId,
      }));
      navigate(DOCUMENTS_UPLOADING);
    }
  }, [dispatch, bookingTime, aptLength, specialistId, navigate, setIsBookingTimeModalOpen]);
  const handleCancel = useCallback(() => setIsBookingTimeModalOpen(false), [setIsBookingTimeModalOpen]);

  const { isLoading: isLoadingAvailableTimeList, data: availableTimeList } = useGetAvailabilitiesBySpecialistQuery({
    specialistId,
    appointmentType,
    month: bookingTime.month,
  });

  const renderDesktop = () => (
    <Row>
      <ColWithBar span={15} style={{ height: '600px', overflow: 'scroll' }}>
        <AvailableTimeslotList
          bookingTime={bookingTime}
          updateBookingTime={updateBookingTime}
          aptLength={aptLength}
          availableTimeList={availableTimeList as AvailableTime[]}
        />
      </ColWithBar>
      <Col span={8}>
        <SelectedTime bookingTime={bookingTime} />
        <Spin spinning={isLoadingAvailableTimeList} tip="loading...">
          <BookingCalendar
            availableTimeList={availableTimeList as AvailableTime[]}
            bookingTime={bookingTime}
            updateBookingTime={updateBookingTime}
          />
        </Spin>
      </Col>
    </Row>
  );

  const renderMobile = () => (
    [
      <SelectedTime bookingTime={bookingTime} />,
      <Spin spinning={isLoadingAvailableTimeList} tip="loading...">
        <BookingCalendar
          availableTimeList={availableTimeList as AvailableTime[]}
          bookingTime={bookingTime}
          updateBookingTime={updateBookingTime}
        />
      </Spin>,
      <AvailableTimeslotList
        bookingTime={bookingTime}
        updateBookingTime={updateBookingTime}
        aptLength={aptLength}
        availableTimeList={availableTimeList as AvailableTime[]}
      />,
    ]
  );

  return (
    <BookingModal
      visible={isBookingTimeModalOpen}
      width={isMobile ? '95%' : '70%'}
      onCancel={handleCancel}
      onOk={handleOK}
      centered
    >
      <div style={{ marginBottom: '20px', overflow: 'scroll' }}>
        <TitleWithIcon icon="table" text="Select an Appointment Time" />
        {(specialistId && availableTimeList) && (isMobile ? renderMobile() : renderDesktop())}
      </div>
    </BookingModal>
  );
};

export default SelectBookingTimeModal;
